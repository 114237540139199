import React, { } from "react"
import { Row, Col, Container, Image } from "react-bootstrap"
import FeaturedEvents from '../Home/FeaturedEvents'
import Slider from "react-slick"
import { useSelector } from "react-redux";
import {isMobile} from 'react-device-detect';

function PublicProfile(props) {
  const apiUrl = useSelector((state) => state.apiUrl);
  const qrCode = {
    className: "center qrSlider",
    centerMode: true,
    infinite: true,
    slidesToShow: 1,
    speed: 500,
    responsive: [
      {
          breakpoint: 767,
          settings: {
              slidesToShow: 1,
          }
      }
    ],
  };
  const qRCodeSliderSettings = {
    slidesToShow: (props.onDashboard || isMobile) ? 1 : 3,
    infinite:true,
    adaptiveHeight:true,
    className: "center qrSlider",
    centerMode: true,
    infinite: true,
    slidesToShow: 1,
    speed: 500,
  }
  return (
    <>
      <div className="tiphub-banner profile">
        <div className="tiphub-banner-bg public_profile_bg"></div>
        <Container>

          <Row className="mb-2">
            <Col sm={12}>
              <div className="dashboard-heading">
                <h2>My Profile</h2>
              </div>
            </Col>
          </Row>
          <div className="profile_banner">
            <Row>
              <Col sm={4}>
                <Image
                  className="profile-image"
                  src={
                    props.userDetails.image !== null
                      ? `${apiUrl}/web/profile_images/${props.userDetails.image}`
                      : "/assets/no-profile-image.png"
                  }
                  roundedCircle fluid
                  /* className="w-50" */
                />
              </Col>
              <Col sm={8}>
                <div className="profile-intro" data-aos="zoom-in">
                  <div className="profile-intro-content">
                    <h2 className="mb-4">{props.userDetails && props.userDetails.name && props.userDetails.name}</h2>
                    <p className="mb-5" style={{ lineBreak: 'anywhere' }} dangerouslySetInnerHTML={{ __html: props.description }}></p>
                    {props.userDetails && props.userDetails.sociallinks.length > 0 && <ul className="tiphub-social-links">
                      {props.userDetails.sociallinks.map((ele, index) => <li key={index}>
                        <a href={ele.url} target="_blank" rel="noopener noreferrer">
                          <img src={`/assets/${ele.plateform}.png`} height={30} />
                        </a>
                      </li>)}
                    </ul>}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <div className="tipping_apps">
        <Container>
          <h1 className="text-center mb-4" data-aos="zoom-in">
            <b>Tipping Apps ({props.services.length})</b>
          </h1>
          <div className="tipping_apps_content">
            <Row>
              <Col sm={12}>
                {props.services.length > 0 && (
                  <ul className="tipping_app_icon">
                    {props.services.map(
                      (ele, index) => <li key={index}>
                        <a
                          onClick={() => props.openDonnerModal(ele)}
                          style={{ cursor: 'pointer' }}
                        >
                          {ele.appname.icon !== null ? 
                          <img src={`/assets/${ele.appname.icon}`} width={50}/>
                          : <img src='/assets/no-profile-image.png' width={50}/>
                          }
                          <span>{ele.appname.name}</span>
                        </a>
                      </li>
                    )}
                  </ul>
                )}
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      {props.qrCodes.length > 0 && <div className="tipping_apps qr_code_content pt-2">
        <Container>
          <h1 className="text-center mb-4" data-aos="zoom-in">
            <b>QR Code</b>
          </h1>
          <div className="tipping_apps_content">
            <Row>
              <Col sm={12}>
                <Slider {...qRCodeSliderSettings}>
                  {props.qrCodes.map(ele=><div key={`qrcode-key-${ele.id}`} className="qrSlider-box">
                    <Image src={`${apiUrl}/web/qrcodes/${ele.image}`} fluid thumbnail className="p-2" />
                  </div>)}
                </Slider>
              </Col>
            </Row>
          </div>
        </Container>
      </div>}
      <FeaturedEvents userId={props.userDetails.id} title="Recent Events" onDashboard={props.onDashboard ? true : false}/>
    </>
  );
}

export default PublicProfile;
