import React from "react";
import { Container, Row, Col, Breadcrumb, Accordion, Card } from "react-bootstrap";
import SocialLink from "./SocialLink";
import Description from "./Description";
import QrCode from "./QrCode";
import PaymentServices from "./PaymentServices"
import PublicProfile from "./PublicProfile";

const Dashboard = (props) => {
  
  return (
    <div className="dashboard-banner">
      <div className="tiphub-banner-bg"></div>
      <Container>
        <Row className="mb-2">
          <Col sm={12}>
            <div className="dashboard-heading">
              <h2><b>Dashboard</b></h2>
              <Breadcrumb className="ms-auto">
                <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                <Breadcrumb.Item active>Dashboard</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </Col>
        </Row>

        <Row>
          <Col md={5}>
            <div className="dashboard-left-align">
              <div className="dashboard-heading">
                <h4>Preview</h4>
              </div>
              <Card className="profile-dashboard-preview">
              <PublicProfile 
                  description={props.description}
                  qrCodes={props.qrCodes}
                  services={props.services}
                  openDonnerModal={props.openDonnerModal}
                  userDetails={props.userDetails}
                  onDashboard={true}
              />
              </Card>
            </div>
          </Col>
          <Col md={7}>
            <div className="dashboard-right-align">
              <div className="dashboard-heading">
                <h4><b>Actions</b></h4>
              </div>
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Tipping Apps</Accordion.Header>
                  <Accordion.Body>
                    <PaymentServices
                      services={props.services}
                      updatePaymentList={props.updatePaymentList}
                    />
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>Social Links</Accordion.Header>
                  <Accordion.Body>
                    <SocialLink
                        socialLinks={props.socialLinks}
                        updateSocialLinks={props.updateSocialLinks}
                    />
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>Description</Accordion.Header>
                  <Accordion.Body>
                    <Description 
                      description={props.description}
                      updateDescription={props.updateDescription}
                    />
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>QR Codes</Accordion.Header>
                  <Accordion.Body>
                    <QrCode 
                      qrCodes={props.qrCodes}
                      updateQrCodeList={props.updateQrCodeList}
                      copyToClipBoard={props.copyToClipBoard}
                    />
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </Col>
        </Row>

      </Container>
    </div>
  );
};

export default Dashboard;
